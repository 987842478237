<template>
  <header class="header">
    <h1 class="left">
      <a href="/"
        ><img v-lazy="require('@/assets/image/logo.png')" alt="嗨喵悦动,嗨喵互动,logo"
      /></a>
    </h1>
    <div class="middle">
      <el-menu
        ref="menu"
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleNavOp"
        class="menu"
      >
        <el-menu-item index="home">
          <div
            @click="handleNavClick('/')"
            class="headerStyle"
            :class="activeIndex === 'home' && 'show-line'"
          >
            首页
            <div class="line2"></div>
          </div>
        </el-menu-item>

        <!-- <el-menu-item index="wedding">
          <img src="@/assets/image/wedding.png" class="versionIcon" alt="嗨喵悦动,嗨喵互动,婚礼版" />
          <div class="line">
            婚礼版
            <div class="line2"></div>
          </div>
          <div class="subtitle">免</div>
        </el-menu-item> -->
        <el-submenu index="wedding">
          <template slot="title">
            <div class="weddingItem">
              <img
                src="@/assets/image/wedding.png"
                class="weddingIcon"
                alt="嗨喵悦动,嗨喵互动,婚礼版"
              />
              婚礼版
            </div>
            <!-- <div class="subtitle">免</div> -->
          </template>
          <el-menu-item index="wedding-0">
            <div class="subItem">通用版</div>
          </el-menu-item>
          <el-menu-item index="wedding-1">
            <div class="subItem">中式婚礼版</div>
          </el-menu-item>
        </el-submenu>
        <!-- <el-submenu index="wedding">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/wedding.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,婚礼版"
              />
              婚礼版
            </div>
            <div class="subtitle">免</div>
          </template>
          <el-menu-item index="wedding-0">
            <div class="sItem">通用版</div>
          </el-menu-item>
          <el-menu-item index="wedding-1">
            <div class="sItem">中式婚礼版</div>
          </el-menu-item>
        </el-submenu> -->
        <el-menu-item index="company">
          <div class="weddingItem">
            <img
              src="@/assets/image/company.png"
              class="companyIcon"
              alt="嗨喵悦动,嗨喵互动,企业版"
            />
            企业版
          </div>
        </el-menu-item>
        <el-submenu index="version">
          <template #title>
            <div class="headerStyle">更多版本</div>
          </template>
          <el-submenu index="brithday" class="birthdaySubMenu">
            <template #title>
              <div class="subMenu">
                <img src="@/assets/image/birthday.png" alt="" />
                <span>生日版</span>
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2426 7.06064L6 2.818L1.75736 7.06064"
                    stroke="rgba(0,0,0,0.4)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
            <!-- 
             case 'birthday-0':
          window.open(BIRTH, '_blank');
          break;
        case 'birthday-1':
          window.open(BABY, '_blank');
          break;
        case 'birthday-2':
          window.open(OLDBIRTHDAY, '_blank');
          break;
        case 'birthday-3':
          window.open(AGE_CEREMONY, '_blank');
          break;
        case 'birthday-4':
          window.open(BLY, '_blank');
          break;
        case 'birthday-5':
          window.open(MYY, '_blank');
          break; -->
            <el-menu-item index="birthday-0">
              <div class="subItem">通用版</div>
            </el-menu-item>
            <el-menu-item index="birthday-1">
              <div class="subItem">宝宝宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-2">
              <div class="subItem">寿宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-3">
              <div class="subItem">成人礼</div>
            </el-menu-item>
            <el-menu-item index="birthday-4">
              <div class="subItem">百露宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-5">
              <div class="subItem">满月宴</div>
            </el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="wedding-0">
            <div class="subItem">
              <img src="@/assets/image/birthday.png" alt="">
              生日版</div>
          </el-menu-item> -->
          <el-submenu index="school" class="birthdaySubMenu">
            <template #title>
              <div class="subMenu">
                <img src="@/assets/image/schoolicon.png" alt="" />
                <span>校园版</span>
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2426 7.06064L6 2.818L1.75736 7.06064"
                    stroke="rgba(0,0,0,0.4)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
            <el-menu-item index="school-0">
              <div class="subItem">毕业典礼</div>
            </el-menu-item>
            <el-menu-item index="school-1">
              <div class="subItem">谢师宴</div>
            </el-menu-item>
            <el-menu-item index="school-2">
              <div class="subItem">金榜题名</div>
            </el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="package">
          <a @click.prevent="handleNavClick('/setmeal')" href="/setmeal">
            <div class="headerStyle" :class="activeIndex === 'package' && 'show-line'">
              婚礼套餐
            </div>
          </a>
        </el-menu-item>
        <el-menu-item index="partner">
          <a href="/hm-customer/index.html#/partnerSystem/login">
            <div class="headerStyle">嗨喵合伙人</div>
          </a>
        </el-menu-item>
        <el-submenu index="resolve">
          <template #title>
            <div class="headerStyle">解决方案</div>
          </template>
          <el-menu-item>
            <a href="/app" target="_blank">
              <div class="subItem">嗨喵APP</div>
            </a>
          </el-menu-item>
          <el-menu-item>
            <!-- <div @click="to('')" class="subItem">嗨喵课程</div> -->
            <a target="_blank" href="/hm-course/">
              <div class="subItem">嗨喵课程</div>
            </a>
          </el-menu-item>
          <el-menu-item>
            <!-- <div @click="to('/wxt')" class="subItem">嗨喵微喜帖</div> -->
            <router-link class="subItem" :to="{ name: 'wxt' }" target="_blank"
              >嗨喵微喜帖</router-link
            >
          </el-menu-item>
          <el-menu-item>
            <a href="/game" target="_blank">
              <div class="subItem">嗨喵游戏</div>
            </a>
          </el-menu-item>
          <el-menu-item>
            <a href="https://www.hunlitang.cn/" target="_blank">
              <div class="subItem">嗨喵婚礼堂</div>
            </a>
          </el-menu-item>
          <el-menu-item>
            <a target="_blank" href="https://ustatic.hudongmiao.com/joymewDesk/joymewDesk.exe">
              <div class="subItem">嗨喵桌面程序</div>
            </a>
            <!-- <div @click="to('https://ustatic.hudongmiao.com/joymewDesk/joymewDesk.exe')" class="subItem">嗨喵桌面程序</div> -->
          </el-menu-item>
          <el-menu-item>
            <a
              target="_blank"
              href="https://ustatic.joymew.com/browsers/ChromeStandaloneSetup64.exe"
            >
              <div class="subItem">Google浏览器下载</div>
            </a>
          </el-menu-item>
        </el-submenu>

        <!-- <el-menu-item index="desk">
          <div class="headerStyle">活动中心</div>
        </el-menu-item> -->
        <!-- <el-menu-item index="version">
          更多版本
        </el-menu-item> -->
        <!-- <el-submenu index="birthday">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/birthday.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,生日版"
              />
              生日版
            </div>
          </template>
          <el-menu-item index="birthday-0">
            <div class="sItem">通用版</div>
          </el-menu-item>
          <el-menu-item index="birthday-1">
            <div class="sItem">宝宝宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-2">
            <div class="sItem">寿宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-3">
            <div class="sItem">成人礼</div>
          </el-menu-item>
          <el-menu-item index="birthday-4">
            <div class="sItem">百露宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-5">
            <div class="sItem">满月宴</div>
          </el-menu-item>
        </el-submenu> -->

        <!-- <el-submenu index="school">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/schoolicon.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,校园版"
              />
              校园版
            </div>
          </template>
          <el-menu-item index="school-0">
            <div class="sItem">毕业典礼</div>
          </el-menu-item>
          <el-menu-item index="school-1">
            <div class="sItem">谢师宴</div>
          </el-menu-item>
          <el-menu-item index="school-2">
            <div class="sItem">金榜题名</div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="version">
          更多版本
        </el-menu-item> -->
        <!-- <el-menu-item index="desk">桌面程序</el-menu-item> -->
        <!-- <el-menu-item index="wxt">
          <div class="line">
            微喜帖
            <div class="line2"></div></div
        ></el-menu-item> -->
        <!-- <el-menu-item index="partner">嗨喵合伙人</el-menu-item> -->
      </el-menu>
    </div>

    <div class="right">
      <div class="r-box">
        <div class="login" @click="openLrBox(0)" v-if="!token" :class="{ iframeEnv: isIframeEnv }">
          登录
        </div>
        <div class="register" @click="openLrBox(2)" v-if="!token">免费注册</div>
        <div
          class="applyActivity publicHvCenter"
          :class="{ iframeEnv: isIframeEnv }"
          v-if="token"
          @click="toApply"
        >
          创建活动
        </div>
        <div
          class="userInfo publicVCenter"
          :class="{ iframeEnv: isIframeEnv }"
          v-if="token"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <img :src="headImg" class="headImg" alt="嗨喵悦动,嗨喵互动,用户头像" />
          <div class="name">{{ userName }}</div>
        </div>
        <div
          class="userInfoSubMenu publicHvCenter"
          :class="{ ani: userInfoShow, iframeEnv: isIframeEnv }"
          v-if="userInfoSubMenuVisible"
          @mouseenter="handleMouseEnter2"
          @mouseleave="handleMouseLeave2"
        >
          <div class="item" @click="toUserCenter">我的账户</div>
          <div class="item" @click="logout">退出登录</div>
        </div>
        <!-- <div class="boxspace"></div> -->
      </div>
    </div>

    <lrBox @close="handleCloseLrBox" v-show="lrBoxVisible" ref="lrBoxDom" />
    <div v-if="false" class="game-container">
      <div class="content">
        <div class="gcc-left">
          <span>其他功能</span>
          <span>1.桌面程序</span>
          <span>2.微喜帖</span>
          <span>3.课程</span>
          <span>4.Google浏览器下载</span>
        </div>
        <div class="gcc-right">
          <div>
            <span>开场签到</span>
            <span>3D签到</span>
            <span>中式签到普</span>
            <span>手写签到</span>
            <span>跑车签到</span>
            <span>启动仪式</span>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import lrBox from "@/components/lrBox.vue";
import { mapState } from "vuex";
import {
  WEDDING,
  WEDDING_ZSHL,
  ENTERPRISE,
  BIRTH,
  MACDESK,
  WINDOWDESK,
  // SCHOOL,
  BABY,
  OLDBIRTHDAY,
  AGE_CEREMONY,
  GRADUATION,
  THANKS,
  NAME_ONBORAD,
  BLY,
  MYY,
  PATNER,
  // NORMAL_VERSION,
} from "@/assets/constant/default";
import { timeoutTask, isIframeEnv } from "@/utils/index";

let mouseOverFlag = false;
export default {
  name: "joymew-header",
  components: {
    lrBox,
  },
  data() {
    return {
      userInfoShow: false,
      isIframeEnv: false,
      userInfoSubMenuVisible: false,
      lrBoxVisible: false,
      activeIndex: "home",
      list: [
        ['开场签到', '3D签到', '中式签到普', '手写签到', '跑车签到', '启动仪式'],
        ['红包游戏', '经典要红包', 'quanmin']
      ]
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      phone: (state) => state.phone,
      userName: (state) => state.userName,
      headImg: (state) => state.headImg,
    }),
  },

  methods: {
    handleNavClick(path) {
      if (this.$route.path == path) {
      } else {
        window.open(path, "_self");
      }
    },
    to(src) {
      window.open(src);
    },
    handleNavOp(e) {
      let target;
      if (this.$route.path === "/") {
        target = "home";
      } else target = "package";
      switch (e) {
        case "home":
          this.$router.push("/");
          this.activeIndex = "home";
          break;
        case "wedding-0":
          window.open(WEDDING, "_blank");
          break;
        case "wedding-1":
          window.open(WEDDING_ZSHL, "_blank");
          break;
        case "company":
          window.open(ENTERPRISE, "_blank");
          break;
        // case 'birthday':
        case "birthday-0":
          window.open(BIRTH, "_blank");
          break;
        case "birthday-1":
          window.open(BABY, "_blank");
          break;
        case "birthday-2":
          window.open(OLDBIRTHDAY, "_blank");
          break;
        case "birthday-3":
          window.open(AGE_CEREMONY, "_blank");
          break;
        case "birthday-4":
          window.open(BLY, "_blank");
          break;
        case "birthday-5":
          window.open(MYY, "_blank");
          break;
        case "school-0":
          window.open(GRADUATION, "_blank");
          break;
        case "school-1":
          window.open(THANKS, "_blank");
          break;
        case "school-2":
          window.open(NAME_ONBORAD, "_blank");
          break;
        case "desk":
          this.downloadDesk();
          break;
        case "partner":
          // window.open(PATNER, '_self');
          break;
        // case 'wxt':
        //   this.$router.push({
        //     path: '/wxt',
        //   });
        //   this.activeIndex = 'wxt';

        //   break;

        default:
          console.log(e);
          break;
      }
      // alert(this.activeIndex)
      this.activeIndex = target;
      this.$refs.menu.activeIndex = target;
      // this.activeIndex = '1';
    },
    downloadDesk() {
      const version = navigator.userAgent.toLowerCase();
      const mac = version.indexOf("mac");
      const os = version.indexOf("os");
      if (mac > 0 && os > 0) {
        window.open(MACDESK, "_blank");
      } else {
        window.open(WINDOWDESK, "_blank");
      }
    },
    openLrBox(type) {
      this.lrBoxVisible = true;
      this.$nextTick(() => {
        this.$refs.lrBoxDom.openBoxByType(type);
      });
    },
    handleCloseLrBox() {
      this.lrBoxVisible = false;
    },
    toApply() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/myActivity/create`;
    },
    handleMouseEnter() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    handleMouseEnter2() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave2() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    logout() {
      this.$store.commit("resetUserInfo");
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
      window.parent.postMessage("clearStorage", "*");
    },
    toUserCenter() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
    },
  },

  created() {
    mouseOverFlag = false;
    this.isIframeEnv = isIframeEnv();
    this.$store.commit("initUserInfo");
    window.addEventListener("message", this.handleMessage);
  },
  mounted() {
    const { path } = this.$route;
    if (path === "/") {
      this.activeIndex = "home";
    } else if (path === "/wxt") {
      this.activeIndex = "wxt";
    }

    if (path == "/setmeal") {
      this.activeIndex = "package";
    }
  },
  changeIndex(e) {
    this.activeIndex = e;
  },
  beforeDestroy() {
    // this.unWatchScroll();
    window.removeEventListener("message", this.handleMessage);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.game-container {
  position: absolute;
  top: 96px;
  left: 0;
  width: 100%;
  height: calc(100vh - 96px);
  background-color: red;
  z-index: 100;
  
  .content {
    height: 628px;
    background-color: aqua;
    display: flex;
    .gcc-left {
      width: 0;
      flex: 0 0 350.67px;
      height: 628px;
      background: #f8f8f8;
      padding: 42.67px 0 0 133.33px;
      display: flex;
      flex-direction: column;

      span {
        font-size: 18.67px;
        color: #919398;
        margin-bottom: 32px;
      }
      span:first-child {
        font-size: 21.33px;
        padding-bottom: 21.33px;
        font-weight: bold;
        width: 176px;
        color: black;
        border-bottom: 1.33px solid #E5E7EB;
        margin-bottom: 42.67px;
      }

      
    }

    .gcc-right {
      flex: 1;
      width: 0;
      background-color: #fff;
      display: flex;
      padding-left: 64px;
      >div {
        width: 133.33px;
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: 32px;
          color: #333333;
        }
        span:first-child {
          font-size: 21.33px;
          font-weight: bold;
          padding: 42.67px 0 21.33px 0;
          border-bottom: 1.33px solid #E5E7EB;
          margin-bottom: 42.67px;
          
        }
      }
    }
  }
}

::v-deep .el-submenu__icon-arrow {
  margin-left: 10px !important;
}
::v-deep .el-menu-item:has(.weddingItem) {
  padding: 0 20px 0 calc(20px + 32px) !important;
}
::v-deep .el-submenu__title:has(.weddingItem) {
  padding: 0 20px 0 calc(20px + 32px) !important;
}
.weddingItem:has(.companyIcon) {
  margin-left: 20px;
}
::v-deep .el-menu-item:has(.companyIcon) {
  padding: 0 20px 0 30px !important;
}

.headerStyle {
  font-weight: bold;
  font-size: 21.33px;
  position: relative;
  &::after {
    position: absolute;
    width: 32px;
    height: 4px;
    // background: #ffcc4f;
    border-radius: 18.67px;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}
.show-line {
  &::after {
    background: #ffcc4f;
  }
}
.weddingItem {
  // margin-left: 28px;
  width: 65px;
  font-weight: bold;
  font-size: 21.33px;
  .weddingIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
  .companyIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
}

.birthdaySubMenu .arrow {
  width: 24px;
  height: 17px;
  transform: rotate(90deg) scale(0.7);
}
.subMenu {
  width: 170px;
  // width: 169.33px;
  // height: 61.33px;
  // padding: 30px 20px;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 21.33px !important;
  // color: #333;
  display: flex;
  align-items: center;
  svg {
    transform: rotate(90deg) scale(1.35);
  }
  span {
    display: inline-block;
    margin: 0 5px;
  }
  // font-weight: bold;
  img {
    width: 22.67px;
    height: 24px;
  }
  &:hover {
    background: rgba(255, 204, 79, 0.1);
    // color: #FFCC4F;
  }
}
.subItem {
  // width: 266.67px;
  // height: 61.33px;
  // color: #919398;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  font-size: 18.67px;
  color: #919398;
  // transition: all .5s;
  &:hover {
    background-color: rgba(255, 204, 79, 0.1);
    color: #ffcc4f;
  }
  img {
    width: 22.67px;
    height: 24px;
  }
}
a {
  color: #333;
}
.header {
  display: flex;
  justify-content: space-between;
  height: 96px;
  align-items: center;
  padding-left: 133px;
  padding-right: 160px;

  .left {
    flex-shrink: 0;
  }

  .left img {
    width: 95px;
    height: 48px;
  }

  // .currentMenu {
  //   width: 145px;
  //   height: 45px;
  //   background: linear-gradient(0deg, #f94928 0%, #fc674d 100%);
  //   border-radius: 29px;
  //   font-size: 24px;
  //   font-weight: 600;
  //   color: rgba(255, 255, 255, 0.9);
  //   letter-spacing: 2px;
  //   text-shadow: 0px 3px 1px 0px #ed2719;
  //   // margin-left: 220px;
  //   cursor: pointer;
  //   position: absolute;
  //   left: 200px;
  // }
  .middle {
    display: flex;
    // justify-content: center;
    // margin-left: 276px;
    // align-items: center;
    margin-left: 48px;
    flex-grow: 1;

    .menu {
      // left: 308px;
      &.el-menu::v-deep {
        // width: 475px;
        height: 52px;
        border-radius: 36px;
        border: 1px solid #ffffff;
        background-color: transparent;
        // margin-left: 143px;
        // margin-right: 53px;
        display: flex;
        align-items: center;
        // justify-content: center;

        // width: 200px;
        .el-menu-item {
          padding: 0 30px !important;
          font-size: 21px;
          color: #333333;
          background-color: unset;
          border-bottom: unset;
          // margin: 0 20px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;

          .subtitle {
            width: 39px;
            height: 19px;
            font-size: 10px;
            line-height: 20px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: -5px;
            top: 0px;
            background: url("~@/assets/image/subtitlebg.png") no-repeat;
            background-size: contain;
            background-position: center;
          }

          .sItem {
            // col;
            font-size: 21.33px;
            // text-align: center;
            width: 150px;
            // &:hover {
            //   color: #ffcc4f;
            //   background-color: rgba(255,204,79,0.10);
            // }
          }

          &.is-active {
            border-bottom: unset;
            color: #ffcc4f;

            .line {
              // background-color: red.;
              // border-bottom: 3px solid #fc9632;
              .line2 {
                width: 32px;
                height: 4px;
                background-color: #ffcc4f;
                margin: 0 auto;
              }
            }
          }

          &:hover {
            background-color: unset;
            color: #ffcc4f;
          }

          .versionIcon {
            vertical-align: text-top;
            // width: 30px;
            height: 30px;
          }
        }

        .el-submenu {
          .subtitle {
            width: 39px;
            height: 19px;
            font-size: 10px;
            line-height: 20px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: 20px;
            top: -0px;
            background: url("~@/assets/image/subtitlebg.png") no-repeat;
            background-size: contain;
            background-position: center;
          }

          .el-submenu__title {
            padding: 0 30px;
            font-size: 21px;
            color: #333333;
            display: flex;
            align-items: center;
            border-bottom: unset;

            .versionIcon {
              width: 30px;
              height: 30px;
              vertical-align: text-top;
            }

            .demonsIcon {
              width: 23px;
              height: 23px;
              margin-right: 4px;
            }

            .el-submenu__icon-arrow {
              font-size: 18px;
              color: #333;
              font-weight: bold;
              margin-left: 40px;
            }

            &:hover {
              background-color: unset;
              color: #ffcc4f;
              .el-submenu__icon-arrow {
                color: #ffcc4f;
              }
            }
          }
        }
      }
    }
  }

  .right {
    display: flex;
    font-size: 26px;
    flex-shrink: 0;
    // width: 380px;
    color: #333;
    justify-content: flex-end;

    // line-height: 100px;
    .r-box {
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;
      .login {
        cursor: pointer;
        font-size: 19px;
        font-weight: 400;
        color: #ffcc4f;
        // margin-left: 191px;
        // &:hover {
        //   color: #fc9632;
        // }

        &.iframeEnv {
          margin-left: 0;
        }
      }

      .register {
        cursor: pointer;
        margin-left: 43px;
        width: 139px;
        height: 53px;
        background: #ffcc4f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        font-weight: 400;
        color: #ffffff;
        // &:hover {
        //   color: #fc9632;
        // }
      }

      .applyActivity {
        font-size: 16px;
        font-weight: 500;
        background-color: #f6c364;
        color: #fff;
        position: relative;
        // right: 118px;
        margin-right: 30px;
        margin-left: 30px;
        cursor: pointer;
        width: 152px;
        height: 45px;
        border-radius: 8px;

        &.iframeEnv {
          // right: 361px;
        }
      }

      .userInfo {
        flex-direction: column;
        cursor: pointer;

        // position: absolute;
        // right: 100px;
        //  height: 100px;
        .headImg {
          width: 38px;
          height: 38px;
          border-radius: 100%;
        }

        .name {
          font-size: 16px;
          color: #666;
          margin-top: 6px;
          width: 94px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          // margin-right: 60px;
        }

        // .boxspace {
        //     width: 100px;
        // }
        &.iframeEnv {
          right: 269px;
        }
      }

      .userInfoSubMenu {
        width: 127px;
        height: 0;
        position: absolute;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 8px;
        top: 68px;
        right: -17px;
        transition: all 0.1s ease-out;
        overflow: hidden;
        z-index: 100;

        &.ani {
          opacity: 1;
          height: 97px;
        }

        .item {
          color: #787a8c;
          font-size: 18px;
          font-weight: 400;
          cursor: pointer;

          &:nth-child(1) {
            margin-bottom: 12px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        &.iframeEnv {
          right: 247px;
        }
      }
    }
  }
}
</style>
